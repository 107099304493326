@font-face {
    font-family: 'Arimo';
  src: url('Arimo-Bold.eot');
    src: url('Arimo-Bold.eot?#iefix') format('embedded-opentype'),
        url('Arimo-Bold.woff2') format('woff2'),
        url('Arimo-Bold.woff') format('woff'),
        url('Arimo-Bold.ttf') format('truetype'),
        url('Arimo-Bold.svg#Arimo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-Italic.eot');
    src: url('Arimo-Italic.eot?#iefix') format('embedded-opentype'),
        url('Arimo-Italic.woff2') format('woff2'),
        url('Arimo-Italic.woff') format('woff'),
        url('Arimo-Italic.ttf') format('truetype'),
        url('Arimo-Italic.svg#Arimo-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo.eot');
    src: url('Arimo.eot?#iefix') format('embedded-opentype'),
        url('Arimo.woff2') format('woff2'),
        url('Arimo.woff') format('woff'),
        url('Arimo.ttf') format('truetype'),
        url('Arimo.svg#Arimo') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-BoldItalic.eot');
    src: url('Arimo-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Arimo-BoldItalic.woff2') format('woff2'),
        url('Arimo-BoldItalic.woff') format('woff'),
        url('Arimo-BoldItalic.ttf') format('truetype'),
        url('Arimo-BoldItalic.svg#Arimo-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'arimobold';
    src: url(//www.dolcevita.com/cdn/shop/files/arimo-bold-webfont.woff2?v=4355571449778633429) format('woff2'),
      url(//www.dolcevita.com/cdn/shop/files/arimo-bold-webfont.woff?v=10461315771398255554) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'arimobold_italic';
      src: url(//www.dolcevita.com/cdn/shop/files/arimo-bolditalic-webfont.woff2?v=3000101096570233045) format('woff2'),
      url(//www.dolcevita.com/cdn/shop/files/arimo-bolditalic-webfont.woff?v=6907939917268016734) format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  @font-face {
    font-family: 'arimoitalic';
      src: url(//www.dolcevita.com/cdn/shop/files/arimo-italic-webfont.woff2?v=7141686234201949844) format('woff2'),
      url(//www.dolcevita.com/cdn/shop/files/arimo-italic-webfont.woff?v=17079126965749191437) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'arimoregular';
      src: url(//www.dolcevita.com/cdn/shop/files/arimo-regular-webfont.woff2?v=9302334437808075133) format('woff2'),
      url(//www.dolcevita.com/cdn/shop/files/arimo-regular-webfont.woff?v=15530881862221905369) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Baskerville MT Std';
    src: url('https://cdn.shopify.com/s/files/1/0037/3807/5202/files/BaskervilleMTStd-Italic.eot?v=1682578576');
    src: url('https://cdn.shopify.com/s/files/1/0037/3807/5202/files/BaskervilleMTStd-Italic.eot?v=1682578576&#iefix') format('embedded-opentype'),
        url('https://cdn.shopify.com/s/files/1/0037/3807/5202/files/BaskervilleMTStd-Italic.woff2?v=1682578576') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0037/3807/5202/files/BaskervilleMTStd-Italic.woff?v=1682578576') format('woff'),
        url('https://cdn.shopify.com/s/files/1/0037/3807/5202/files/BaskervilleMTStd-Italic.ttf?v=1682578576') format('truetype');
    font-weight: normal;
    /* font-style: italic; */
    font-display: swap;
  }
  @font-face {
    font-family: 'Charpentier Sans Pro';
    src: local('Charpentier Sans Pro Leger'), local('Charpentier-Sans-Pro-Leger'),
        url('//cdn.shopify.com/s/files/1/0037/3807/5202/files/CharpentierSansPro-Leger.woff2') format('woff2'),
        url('//cdn.shopify.com/s/files/1/0037/3807/5202/files/CharpentierSansPro-Leger.woff') format('woff'),
        url('//cdn.shopify.com/s/files/1/0037/3807/5202/files/CharpentierSansPro-Leger.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }